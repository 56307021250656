import React, { useState } from "react"

import useSiteMetadata from "../hooks/use-site-metadata"
import { renderSocialIcons } from "../lib/utils"

import Menu from "./menu"
import Seperator from "./seperator"

/**
 * ? Provides global site navigation
 */
const Navigation = () => {
    const { 
        artist,
        handle, 
        facebook,
        twitter,
        instagram, 
        soundcloud, 
        youtube
    } = useSiteMetadata()
    const social = { facebook, twitter, instagram, soundcloud, youtube }
    const [ menuIsVisibile, setMenuVisibility ] = useState(false)
    return(
        <>
            <div 
                className="navigation__btn"
                onClick={() => setMenuVisibility(true)}
            >
                <i className="fas fa-bars animated fadeInLeft slower"/>
            </div>
            <Menu 
                header={artist}
                isVisible={menuIsVisibile}
                closeMenu={() => setMenuVisibility(false)}
                social={social}
            />
            <div className="navigation__content">
                <div className="navigation__content--container">
                    <span className="animated fadeInRight slower">
                        SHARE
                    </span>
                    <span id="seperator" className="animated fadeIn slower">
                        <Seperator rotate={-90} spacing={60}/>
                    </span>
                    <span className="animated fadeInRight slower">
                        <i className="far fa-copyright"/> &nbsp;
                        {new Date().getFullYear()} &nbsp; &nbsp;
                        Follow &nbsp; &nbsp; &nbsp;
                        <a href={ instagram }>{ handle }</a>
                    </span>
                </div>
            </div>
            <div className="navigation__btn share">
                <i className="fas fa-plus"/>
                <div className="navigation__btn--social-banner">
                    {renderSocialIcons(social)}
                </div>
            </div>
        </>
    )
}

export default Navigation