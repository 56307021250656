import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            handle
            artist
            instagram
            soundcloud
            youtube
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata