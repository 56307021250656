import React from "react"
import PropTypes from "prop-types"

import UI from "./ui"
import Navigation from "./navigation"

/**
 * ? Flexible page layout to structure navigation and content  
 */
const Page = ({ children }) => {
  return (
    <UI>
      <div className="page__layout is-horizontal">
        {/* Navigation */}
        <nav className="page__navigation">
          <div className="page__navigation--container is-fluid is-fixed">
            <Navigation/>
          </div>
        </nav>
        {/* Page */}
        <div className="page__container">
          {/* Content */}
          <main className="page__content--container is-horizontal is-fluid">
            { children } {/* Page content */}
          </main>
          {/* Footer */}
          <footer className="page__footer">
            {/* Footer content */}
            © {new Date().getFullYear()} &nbsp; 
            Built with &nbsp;{`💖`} &nbsp;by &nbsp;
            <a href="https://www.liontech.nyc">Lion Tech NYC</a>
          </footer>
        </div>
      </div>
    </UI>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
