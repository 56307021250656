import React from "react"
import PropTypes from "prop-types"

const Seperator = ({ size, rotate, spacing }) => {
    return <div
        className="seperator"
        style={{
            width: size,
            transform: `rotate(${rotate}deg)`,
            margin: `${spacing}px 0`
        }}
    />
}

Seperator.propTypes = {
    size: PropTypes.number,
    rotate: PropTypes.number,
    spacing: PropTypes.number
}

Seperator.defaultProps = {
    size: 60,
    rotate: 0,
    spacing: 20
}

export default Seperator